$title-font: "Oswald", "Roboto", sans-serif;
$san-serif: "Roboto", "Arial Black", sans-serif;
$serif: "Libre Baskerville", "Times", serif;

$selection-bg: #555;
$anchor-color: #356eff;
$active-color: #356eff;
$light-active-color: rgb(184, 222, 253);

$disabled-color: #aaa;
$danger: darkred;

$very-dark: #252525;

$mobile-header-height: 48px;
