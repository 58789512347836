.mt1 {
    margin-top: 8px;
}

.mt2 {
    margin-top: 16px;
}

.mt3 {
    margin-top: 24px;
}

.mt4 {
    margin-top: 32px;
}

.no-margin {
    margin: 0 !important;
}

.h-flex {
    display: flex;
    flex-direction: row;

    &.as {
        align-items: flex-start;
    }
    &.ac {
        align-items: center;
    }
    &.ae {
        align-items: flex-end;
    }
    &.js {
        justify-content: flex-start;
    }
    &.jc {
        justify-content: center;
    }
    &.je {
        justify-content: end;
    }
    &.jb {
        justify-content: space-between;
    }
}
.v-flex {
    display: flex;
    flex-direction: column;

    &.as {
        align-items: flex-start;
    }
    &.ac {
        align-items: center;
    }
    &.ae {
        align-items: flex-end;
    }
    &.js {
        justify-content: flex-start;
    }
    &.jc {
        justify-content: center;
    }
    &.je {
        justify-content: end;
    }
    &.jb {
        justify-content: space-between;
    }
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.bg-container {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
