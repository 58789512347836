/* oswald-200 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 200;
    src: url("/assets/fonts/oswald-v31-latin-200.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-200.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-200.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-200.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-200.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-200.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-300 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/oswald-v31-latin-300.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-300.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-300.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-300.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/oswald-v31-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-regular.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/oswald-v31-latin-500.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-500.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-500.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-500.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-500.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-500.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/oswald-v31-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-600.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-600.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-600.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-600.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-600.svg#Oswald") format("svg"); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/oswald-v31-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""), url("/assets/fonts/oswald-v31-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/oswald-v31-latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/oswald-v31-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/oswald-v31-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/oswald-v31-latin-700.svg#Oswald") format("svg"); /* Legacy iOS */
}

/* libre-baskerville-regular - latin */
@font-face {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/libre-baskerville-v7-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Libre Baskerville"), local("LibreBaskerville-Regular"),
        url("/assets/fonts/libre-baskerville-v7-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/libre-baskerville-v7-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/libre-baskerville-v7-latin-regular.svg#LibreBaskerville") format("svg"); /* Legacy iOS */
}
/* libre-baskerville-700 - latin */
@font-face {
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/libre-baskerville-v7-latin-700.eot"); /* IE9 Compat Modes */
    src: local("Libre Baskerville Bold"), local("LibreBaskerville-Bold"),
        url("/assets/fonts/libre-baskerville-v7-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/libre-baskerville-v7-latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/libre-baskerville-v7-latin-700.svg#LibreBaskerville") format("svg"); /* Legacy iOS */
}
/* libre-baskerville-italic - latin */
@font-face {
    font-family: "Libre Baskerville";
    font-style: italic;
    font-weight: 400;
    src: url("/assets/fonts/libre-baskerville-v7-latin-italic.eot"); /* IE9 Compat Modes */
    src: local("Libre Baskerville Italic"), local("LibreBaskerville-Italic"),
        url("/assets/fonts/libre-baskerville-v7-latin-italic.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/libre-baskerville-v7-latin-italic.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-italic.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/libre-baskerville-v7-latin-italic.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/libre-baskerville-v7-latin-italic.svg#LibreBaskerville") format("svg"); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
    src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/roboto-v20-latin-300.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/roboto-v20-latin-300.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/roboto-v20-latin-300.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/roboto-v20-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("Roboto"), local("Roboto-Regular"), url("/assets/fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/roboto-v20-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/roboto-v20-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/roboto-v20-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
    src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/assets/fonts/roboto-v20-latin-500.woff2") format("woff2"),
        /* Super Modern Browsers */ url("/assets/fonts/roboto-v20-latin-500.woff") format("woff"),
        /* Modern Browsers */ url("/assets/fonts/roboto-v20-latin-500.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("/assets/fonts/roboto-v20-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
