@import "../../Template/variables.scss";

#in-now-cover {
  width: 100%;
  display: flex;
  flex-direction: row;

  height: 56.25vw;
  max-height: 100vh;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: 160vw;
  }

  & .in-now-cover-part {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background-position: top;

    transition: 150ms;

    @media only screen and (max-width: 600px) {
      width: 100%;
      height: 50%;
    }

    &:hover {
      filter: brightness(1) !important;
    }
  }

  transition: 50ms;

  &:hover {
    & .in-now-cover-part {
      filter: brightness(0.6);
    }
  }
}

#in-now-banner {
  font-size: 1.25rem;
  font-family: $san-serif;
  width: 100%;
  background-color: #222;
  color: white;
  text-align: center;
  padding: 12px;
}

.in-now-overlay {
  width: 100%;
  padding: 0 2vw;

  @media only screen and (max-width: 600px) {
    padding: 0 12px;
    align-items: center !important;
  }

  & .in-now-title {
    font-family: $san-serif;
    font-weight: 500;
    font-size: 5rem;
    color: white;
    text-shadow: 0 0 12px black;

    @media only screen and (max-width: 600px) {
      font-size: 3rem;
    }
  }

  .in-now-button-group {
    margin: 0 -16px;
    display: flex;
    flex-direction: column;

    & button {
      height: unset;
      padding: 4px 12px;
      width: 400px;
      max-width: 40vw;
      line-height: normal;

      font-size: 2rem;
      border: 0;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(10px);
      font-weight: normal;
      margin: 12px;
      transition: 100ms;

      &:hover {
        background: rgba(0, 0, 0, 0.75);
        color: white;
      }

      @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }
  }
}
