@import "../../Template/variables.scss";

@media only screen and (max-width: 600px) {
    .showcase-container {
        display: flex;
        width: 100%;
    }

    .showcase-main {
        .showcase-toolbar {
            padding: 0;
        }

        .ReactVirtualized__Grid {
            padding: 0;
        }
    }

    .showcase-second-filters {
        width: 100%;
        flex-direction: column;
        margin: 0;

        & > div {
            width: 100%;
            margin: 8px 0;
            & > div {
                width: 100%;
            }
        }
    }

    .showcase-card {
        & .tags-holder {
            & .showcase-card-tag {
                margin: 4px;
                padding: 4px;
                font-size: 0.75rem;
            }
        }
    }

    .showcase-item-quick-view-modal {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: auto;
    }

    .showcase-item-detail-inner {
        width: 100%;
        flex-direction: column;

        & .sdi-image-container {
            margin-top: $mobile-header-height;
            width: 100%;
            height: 100vw;
        }

        & .sdi-main {
            width: 100%;
            padding: 12px;
            background: #eee;
        }
    }
}
