.animated {
    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animated.delay-1s {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.animated.delay-2s {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.animated.delay-3s {
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

.animated.delay-4s {
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.animated.delay-5s {
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

.animated.slower {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
}

.animated.slow {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.animated.fast {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
}

.animated.faster {
    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
}

.animated.fastest {
    -webkit-animation-duration: 100ms;
    animation-duration: 100ms;
}

@media (print), (prefers-reduced-motion: reduce) {
    .animated {
        -webkit-animation-duration: 1ms !important;
        animation-duration: 1ms !important;
        -webkit-transition-duration: 1ms !important;
        transition-duration: 1ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(1.1);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(1.1);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(1.1);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(1.1);
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}
